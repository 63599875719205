import { useLocation } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import React from 'react';

import './search-form.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const SearchForm = () => {
    const location = useLocation();
    const applyFilters = (values, actions) => {
        // Navigate reach-router to the query parameters to keep history and make
        // it possible to bookmark links.
        navigate(`/search?${queryString.stringify(values)}`);
        actions.setSubmitting(false);
    };

    return (
        <Formik
            onSubmit={applyFilters}
            initialValues={queryString.parse(location.search)}
            enableReinitialize={true}>
            <Form className="search">
                <label htmlFor="keywords">Search: </label>
                <Field name="keywords" placeholder="Search" />
                <button type="submit">
                    <i className="bi bi-search"></i>
                </button>
            </Form>
        </Formik>
    );
};

export default SearchForm;
