import React, { useEffect } from 'react';

import logo from '../../images/logo.svg';
import SearchForm from '../content/SearchForm/SearchForm';
import MainMenu from '../menu/MainMenu/MainMenu';
import ServiceMenu from '../menu/ServiceMenu/ServiceMenu';
import './header.scss';

const Header = () => {
    useEffect(() => {
        const headerMenuToggle = document.querySelector(
            '.header__menu--toggle',
        );

        if (headerMenuToggle) {
            headerMenuToggle.addEventListener('click', function (e) {
                if (e.target.tagName !== 'BUTTON') {
                    e.target.parentElement.classList.toggle('open');
                } else {
                    e.target.classList.toggle('open');
                }
            });
        }

        let subMenu = document.querySelectorAll('.submenu');

        let i;
        for (i = 0; i < subMenu.length; i++) {
            let dropdownBtn = document.createElement('BUTTON');
            dropdownBtn.innerHTML = '<i class="bi bi-chevron-down"></i>';
            dropdownBtn.className = 'submenu__toggle';

            dropdownBtn.addEventListener('click', function (e) {
                this.classList.toggle('is-open');
                this.parentElement.classList.toggle('menu-open');
            });

            subMenu[i].parentElement.appendChild(dropdownBtn);

            subMenu[i].parentElement.classList.add('has-submenu');
        }

        window.addEventListener('click', function (e) {
            if (e.target.tagName !== 'I') {
                let hasSubMenu = document.querySelectorAll('.has-submenu');

                for (i = 0; i < hasSubMenu.length; i++) {
                    hasSubMenu[i].classList.remove('menu-open');
                }

                let toggleBtn = document.querySelectorAll('.submenu__toggle');

                for (i = 0; i < toggleBtn.length; i++) {
                    toggleBtn[i].classList.remove('is-open');
                }
            }
        });
    }, []);

    return (
        <header>
            <div className="container">
                <div className="header__inner">
                    <div className="header__logo">
                        <a className="logo" href="/">
                            <img src={logo} alt="TEGOVA logo" />
                        </a>
                    </div>

                    <button className="header__menu--toggle">
                        <span className="toggle__hamburger"></span>
                        <span className="toggle__text">Menu</span>
                    </button>

                    <div className="header__menu">
                        <div className="d-block d-lg-none">
                            <MainMenu />
                        </div>
                        <div className="header__search">
                            <SearchForm />
                        </div>
                        <ServiceMenu />
                        <div className="btn__intranet">
                            <a href="https://intranet.tegova.org/"
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-secondary btn-icon">
                                <i className="bi bi-person"></i>Intranet
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-blue-dark d-none d-lg-block">
                <div className="container">
                    <MainMenu />
                </div>
            </div>
        </header>
    );
};

export default Header;
