import Menu from 'gatsby-plugin-drupal-menus';
import React from 'react';

import './service-menu.scss';

const ServiceMenu = () => {
    return <Menu menuName="website-service-menu" />;
};

export default ServiceMenu;
