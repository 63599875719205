import Menu from 'gatsby-plugin-drupal-menus';
import React from 'react';

import './footer-menu.scss';

const FooterMenu = () => {
    return <Menu menuName="website-footer-menu" />;
};

export default FooterMenu;
