import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';

import './share-buttons.scss';

const ShareButtons = ({ url }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        ogTitle
                        ogImage {
                            src
                            width
                            height
                        }
                    }
                }
            }
        `,
    );

    return (
        <div className="share-buttons">
            <span className="share-buttons--label">Share:</span>
            <FacebookShareButton url={url}>
                <FacebookIcon
                    size={30}
                    round={false}
                    borderRadius={1}
                    iconFillColor={'#004BA0'}
                />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={site.siteMetadata.title}>
                <TwitterIcon
                    size={30}
                    round={false}
                    borderRadius={1}
                    iconFillColor={'#004BA0'}
                />
            </TwitterShareButton>

            <LinkedinShareButton url={url}>
                <LinkedinIcon
                    size={30}
                    round={false}
                    borderRadius={1}
                    iconFillColor={'#004BA0'}
                />
            </LinkedinShareButton>
        </div>
    );
};
export default ShareButtons;
