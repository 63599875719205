import React from 'react';

import FooterMenu from '../menu/FooterMenu/FooterMenu';
import './footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer__inner">
                    <div className="footer__menu">
                        <FooterMenu />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
