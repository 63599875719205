import Menu from 'gatsby-plugin-drupal-menus';
import React from 'react';

import './main-menu.scss';

const MainMenu = () => {
    return <Menu menuName="website-main-menu" />;
};

export default MainMenu;
