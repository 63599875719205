/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import CookieConsent from 'react-cookie-consent';

import ShareButtons from '../content/ShareButtons/ShareButtons';
import Footer from './footer';
import Header from './header';
import './layout.scss';

const Layout = ({ children }) => {
    const location = useLocation();
    return (
        <>
            <Header />
            <div className="fluid-container">
                <main>{children}</main>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col d-flex justify-content-end">
                        <ShareButtons url={location.href} />
                    </div>
                </div>
            </div>
            <CookieConsent
                location="bottom"
                cookieName="tegova-cookie-consent"
                buttonText="Accept"
                enableDeclineButton
                declineButtonText="Decline"
                style={{ background: '#2B373B', padding: "30px" }}
                declineButtonStyle={{
                    background: 'transparent', 
                    color: '#dee2e6',
                    margin: 0,
                    fontSize: '18px',
                }}
                contentStyle={{
                    flex: "1 0 300px",
                    margin: "15px",
                    fontSize: "18px"
                }}
                buttonStyle={{ 
                    background: '#28a745', 
                    color: '#fff',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    padding: '10px 20px',

                }}>
                We use cookies to ensure the proper and secure functioning of
                our website. For further details, please read our{' '}
                <Link to="/privacy-policy">privacy policy</Link>.
            </CookieConsent>
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
